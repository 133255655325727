<template>
  <el-drawer :visible.sync="showExampleDialog" title="日志记录" size="70%">
    <div style="padding: 0 20px" id="loading-wrap" class="example-dialog-wrap">
      <tablePagination :total="0">
        <el-table :data="sopHistoryList" border size="mini">
          <el-table-column type="index" label="序号"></el-table-column>
          <el-table-column width="210" label="群组信息">
            <template slot-scope="scope">
              <div style="font-size: 13px;font-weight: 600;">{{scope.row.SCENE_NAME}}</div>
              <div style="font-size: 10px;font-style: italic">
                <i style="color: #2dacd1" class="el-icon-s-order"></i>素材来源:{{scope.row.MATERIAL_CODE}}
              </div>
              <div style="font-size: 10px;font-style: italic">
                <i style="color: #55a532" class="el-icon-s-promotion"></i>来自旅程:{{scope.row.ROUTE_NAME}}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="CREATE_DATE" width="90" label="创建日期"></el-table-column>
          <el-table-column prop="STATUS" label="发送状态">
          </el-table-column>
          <el-table-column prop="status" label="日志记录">
            <template slot-scope="scope">
              <p style="white-space: pre-line;" v-html="scope.row.REMARK"></p>
            </template>
          </el-table-column>
        </el-table>
      </tablePagination>

    </div>
    <el-empty v-if="sopHistoryList.length<=0">
      <template slot="description">
        <i v-if="exLoading" style="font-size: 40px" class="el-icon-loading"></i>
        <span v-else>暂无</span>
      </template>
    </el-empty>
  </el-drawer>
</template>

<script>
import tablePagination from '@c/tablePagination'

export default {
name: "exampleDialog",
  data(){
    return {
      sopHistoryList:[],
      showExampleDialog:false,
      exLoading:true,
    }
  },
  components:{tablePagination},
  methods:{
    getSopHistoryList(id){
      this.sopHistoryList = []
      this.exLoading = true
      this.showExampleDialog = true
      this.$apiGet('lechun-cms/scrmRoute/getSopSendHistory', { customerId:id}).then(res => {
        this.exLoading = false
        this.sopHistoryList =res;

        //     res.map(item=>{
        //   for(let key in item['case c']){
        //     item.status = item['case c'][key]
        //     item.result = key
        //   }
        //   return item
        // })

      })
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.example-dialog-wrap{
  .el-card__header{
    border-bottom: 1px solid #d7d7d7!important;
  }
  .chat-item{
    display: flex;
    margin-bottom: 20px;
    font-size: 12px;
  }

  .chat-item-reverse{
    flex-direction: row-reverse;
  }
  .chat-img{
    width: 28px;
    display: block;
    margin-right: 10px;
  }
  .chat-img img{
    width: 28px!important;
  }
  .chat-item-reverse .chat-img{
    margin-left: 10px;
    margin-right: 0;
  }
  .chat-content{
    background: #f5f5f5;
    padding: 10px;
    border-radius: 5px;
    display: inline-block;
    white-space: pre-wrap;
    max-width: 80%;
  }
  .chat-item-reverse .chat-content{
    background: #a6e860;
  }
  .chat-miniprogram{
    border: 1px solid #dddddd;
    border-radius: 5px;
    padding: 10px;
    width: 200px;
  }
}

</style>
