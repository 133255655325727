<template>
    <el-drawer :visible.sync="showExampleDialog" title="订单记录" size="70%">
        <div style="padding: 0 20px" id="loading-wrap" class="example-dialog-wrap">
            <tablePagination :total="0">
                <el-table :data="orderList" border size="mini">
                    <el-table-column type="index" label="序号"></el-table-column>
                    <el-table-column prop="orderMainNo" width="90" label="订单号"></el-table-column>
                    <el-table-column prop="createTime" width="90" label="下单时间"></el-table-column>
                    <el-table-column prop="orderClass" width="90" label="订单类型"></el-table-column>

                    <el-table-column prop="orderSource" label="订单来源"></el-table-column>
                    <el-table-column prop="status" label="订单状态"></el-table-column>
                    <el-table-column prop="statusClass" label="订单分类"></el-table-column>
                    <el-table-column prop="channelName" label="渠道名称"></el-table-column>
                    <el-table-column prop="totalAmount" label="订单总价"></el-table-column>
                    <el-table-column prop="orderAmount" label="订单金额"></el-table-column>
                    <el-table-column prop="payAmount" label="支付金额"></el-table-column>
                    <el-table-column prop="freight" label="运费"></el-table-column>
                    <el-table-column prop="bindTime" label="绑定时间"></el-table-column>
                </el-table>
            </tablePagination>
        </div>
        <el-empty v-if="orderList.length<=0">
            <template slot="description">
                <i v-if="exLoading" style="font-size: 40px" class="el-icon-loading"></i>
                <span v-else>暂无</span>
            </template>
        </el-empty>
    </el-drawer>
</template>

<script>
    import tablePagination from '@c/tablePagination'

    export default {
        name: "exampleDialog",
        data(){
            return {
                orderList: [],
                total: 0,
                showExampleDialog: false,
                exLoading: true,
                formParam: {
                    pageSize: 20,
                    currentPage: 1,
                    customerId: '',
                    start: '',
                    end: '',
                    orderClass: null,
                    statusClass: null
                }
            }
        },
        components: {tablePagination},
        methods: {
            getOrderList(id){
                this.orderList = []
                this.exLoading = true
                this.formParam.customerId = id
                this.showExampleDialog = true
                this.$apiGet('lechun-bi/order/getOrderList', this.formParam).then(res => {
                    this.exLoading = false
                    this.total = res.total;
                    this.orderList = res.list;
                })
            }
        }
    }
</script>

<style lang="scss" rel="stylesheet/scss">
    .example-dialog-wrap {
        .el-card__header {
            border-bottom: 1px solid #d7d7d7 !important;
        }
        .chat-item {
            display: flex;
            margin-bottom: 20px;
            font-size: 12px;
        }

        .chat-item-reverse {
            flex-direction: row-reverse;
        }
        .chat-img {
            width: 28px;
            display: block;
            margin-right: 10px;
        }
        .chat-img img {
            width: 28px !important;
        }
        .chat-item-reverse .chat-img {
            margin-left: 10px;
            margin-right: 0;
        }
        .chat-content {
            background: #f5f5f5;
            padding: 10px;
            border-radius: 5px;
            display: inline-block;
            white-space: pre-wrap;
            max-width: 80%;
        }
        .chat-item-reverse .chat-content {
            background: #a6e860;
        }
        .chat-miniprogram {
            border: 1px solid #dddddd;
            border-radius: 5px;
            padding: 10px;
            width: 200px;
        }
    }

</style>
